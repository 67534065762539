import React, { FC } from 'react';
import { Box } from '@mui/material';
import { Text } from 'modules/theme/components/text/Text';
import Link from 'components/link/Link';
import {AppConfigService} from 'modules/appConfig/AppConfigService';

export const AdditionalServiceLink: FC = () => {
    return (
        <Box textAlign={'center'}>
            <Text variant={'body2'}>
                <Link
                    href={AppConfigService.getNehnutelnostiUrl() + '/prihlasenie'}
                    sx={{ textDecoration: 'underline' }}
                >
                    Vytvoriť zadarmo konto Nehnuteľnosti.sk
                </Link>
            </Text>
        </Box>
    );
};
