import {FC, ReactNode} from 'react';
import {Text} from 'modules/theme/components/text/Text';
import {Box} from '@mui/material';

export interface IIntro {
    icon: ReactNode;
    desc: string | ReactNode;
    title: string | ReactNode;
}

export const Intro: FC<IIntro> = ({title, desc, icon}) => {

    return (
        <Box
            display="flex"
            textAlign="center"
            alignItems="center"
            flexDirection="column"
            sx={{backgroundColor: 'inherit'}}
        >
            <Box mb={2}>
                {icon}
            </Box>

            <Box maxWidth={650} mb={2}>
                <Text
                    semibold
                    mobileVariant='h4'
                    variant='h2'
                >
                    {title}
                </Text>
            </Box>

            <Box maxWidth={600} sx={{ whiteSpace: 'break-spaces'}}>
                <Text variant="body2">{desc}</Text>
            </Box>
        </Box>
    );
};