import React, {FC} from 'react';
import {Box, Theme, useMediaQuery, Container} from '@mui/material';
import useSession from 'modules/state/app/hook/session/useSession';
import {Headline} from 'components/page/realEstate/common/additionalServices/components/partials/headline/Headline';
import {
    AdditionalServicesList
} from 'components/additionalServicesList/AdditionalServicesList';
import {
    AdditionalServiceLink
} from 'components/page/realEstate/common/additionalServices/components/partials/card/AdditionalServiceLink';
import ListingIcon from 'modules/theme/components/icons/module/Listing.svg';
import LandCheckIcon from 'modules/theme/components/icons/module/LandCheck.svg';
import RentIcon from 'modules/theme/components/icons/module/Rent.svg';
import ValuationIcon from 'modules/theme/components/icons/module/Valuation.svg';
import { Svg } from 'modules/theme/components/svg/Svg';
import HouseOutlineIcon from 'modules/theme/components/icons/category/HouseOutline.svg';
import PieChartOutlineIcon from 'modules/theme/components/icons/module/PieChartOutline.svg';

const additionalServices = [
    {
        icon: <Svg icon component={HouseOutlineIcon} color={'identityBasicRed'} width={32} height={32}/>,
        name: 'Predaj\nnehnuteľnosti',
        url: '/pridanie/#predaj',
    },
    {
        icon: <Svg icon component={RentIcon} color={'identityBasicBlue'} width={32} height={32}/>,
        name: 'Prenájom\nnehnuteľnosti',
        url: '/pridanie/#prenajom',
    },
    {
        icon: <Svg icon component={ValuationIcon} color={'identityBasicPink'} width={32} height={32} />,
        name: 'Oceňovanie\nnehnuteľnosti',
        url: '/ocenovanie-nehnutelnosti/',
    },
    {
        icon: <Svg icon component={LandCheckIcon} color={'identityBasicGreen'} width={32} height={32}/>,
        name: 'Overovanie\npozemkov',
        url: '/trhove-reporty/marketplace/overovanie-pozemkov',
    },
    {
        icon: <Svg icon component={PieChartOutlineIcon} color={'identityDarkPink'} width={32} height={32}/>,
        name: 'Trhové\nreporty',
        url: '/trhove-reporty',
    },
    {
        icon: <Svg icon component={ListingIcon} color={'labelSecondary'} width={32} height={32}/>,
        name: 'Zmluvy\na informácie',
        url: '/informacie/',
    },
];

export const AdditionalServices: FC = () => {
    const {session} = useSession();
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
    const showAdditionalServiceLink = !!session;

    return (
        <Container maxWidth={isMobile ? false : 'lg'}>
            <Box mb={4}>
                <Headline/>
            </Box>

            <Box mb={1}>
                <AdditionalServicesList additionalServices={additionalServices} showMobilePagination={isMobile}/>
            </Box>

            {!showAdditionalServiceLink && <AdditionalServiceLink/>}
        </Container>
    );
};
